import autoPartsImage from "./assets/private-auto-parts.png";
import historicalSocietyImage from "./assets/head-of-the-lake.png";

const portfolioItems = [
  {
    id: 1,
    title: "Private Auto Parts",
    description:
      "PrivateAutoParts.com is a newly launched project that aims to provide a platform for private individuals to sell vehicle parts through an online classifieds system. The website offers a convenient way for users to buy and sell auto parts while connecting with other car enthusiasts.",
    client: "Andrew Dixon",
    projectType: "Website",
    date: "April, 2024",
    image: autoPartsImage,
    website: "http://www.privateautoparts.com/",
  },
  {
    id: 2,
    title: "The Head of the Lake Historical Society",
    description:
      "The Head of the Lake Historical Society recently launched their new website, developed to showcase the history of Hamilton, Ontario. This project aims to provide a comprehensive platform for visitors to explore and learn about the rich heritage of the town.",
    client: "Terry Watson",
    projectType: "Website",
    date: "May, 2023",
    image: historicalSocietyImage,
    website: "http://www.headofthelake.ca/",
  },
];

export default portfolioItems;
