import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Image, Modal } from "react-bootstrap";

import portfolioItems from "../PortfolioData";

const PortfolioItem = () => {
  let { id } = useParams();
  const item = portfolioItems.find((p) => p.id === parseInt(id));
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div>
      <Container>
        {item ? (
          <>
            <h1
              className={`text-center  page-title ${
                animate ? "heading-text-animation delay-1" : "hidden-initially"
              }`}
            >
              {item.title}
            </h1>
            <div
              id="outter-divider"
              className={` ${
                animate ? "heading-line-animation delay-2" : "hidden-initially"
              }`}
            ></div>

            <Row
              className="justify-content-md-center"
              style={{ overflowX: "hidden" }}
            >
              <Col md={12} lg={6} className="mb-4">
                <Image
                  src={item.image}
                  alt={item.title}
                  fluid
                  className={`item-image ${
                    animate ? "card-animate-pop delay-3" : "hidden-initially"
                  }`}
                  onClick={handleShow}
                />
              </Col>
              <Col
                md={12}
                lg={6}
                className={`mb-4 ${
                  animate
                    ? "slide-in-right-animation delay-4"
                    : "hidden-initially"
                }`}
              >
                <p>{item.description}</p>
                <p>
                  <strong>Client:</strong> {item.client}
                </p>
                <p>
                  <strong>Project Type:</strong> {item.projectType}
                </p>
                <p>
                  <strong>Date:</strong> {item.date}
                </p>
                <p>
                  <strong>Link:</strong>{" "}
                  <a href={item.website} alt={item.client}>
                    {item.website}
                  </a>
                </p>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col>
              <p>Portfolio item not found!</p>
            </Col>
          </Row>
        )}

        <Modal
          show={show}
          onHide={handleClose}
          centered
          size="xl"
          className="image-modal"
        >
          <Modal.Header
            closeButton
            className="image-modal-header"
            closeVariant="white"
          >
            <Modal.Title>{item?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image src={item?.image} alt={item?.title} fluid />
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default PortfolioItem;
